<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Dashboard" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>{{ this.$route.name }}</h1>
            <a-divider/>
            <div class="report">
                <div class="report__filter">
                    <div class="filter-item">
                        <span><a-icon type="clock-circle" /> Thời gian: </span>
                        <a-date-picker :locale="locale" :defaultValue="currentDate" @change="onChange" />

                        <a-select :showArrow="false" showSearch :filterOption="filterOption" placeholder="Chọn ds salon" style="min-width: 150px; max-width: 500px; margin-left: 20px" @change="onSelectChange">
                            <a-select-option v-for="item in getSalons" :key="item.id" :value="item.id">{{ item.shortName }}</a-select-option>
                        </a-select>

                        <a-button style="margin-left: 20px" type="primary" @click="handleGetReport" icon="export">Xem dữ liệu</a-button>
                    </div>
                </div>
                <div class="header__action">

                </div>
            </div>
            <a-divider/>
            <a-table :columns="columns" :dataSource="data" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="pagination ">
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 8px"
                >
                    <a-input
                            v-ant-ref="c => searchInput = c"
                            :placeholder="`Tìm kiếm ${column.title}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                            style="width: 188px; margin-bottom: 8px; display: block;"
                    />
                    <a-button type="primary" @click="() => handleSearch(selectedKeys, confirm)" size="small" style="width: 90px; margin-right: 8px">Tìm kiếm</a-button>
                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined, fontWeight: filtered ? 'bold': undefined }"
                />
                <template slot="complete" slot-scope="text, record">
                    <div style="text-align: center" :key="record.staffTurnId">
                        <a-checkbox :checked="record.complete" />
                    </div>
                </template>
                <template slot="pass" slot-scope="text, record">
                    <div style="text-align: center" :key="record.staffTurnId">
                        <a-checkbox :checked="record.pass" />
                    </div>
                </template>
                <template slot="action" slot-scope="text, record">
                    <div style="text-align: center" :key="record.staffTurnId">
                        <a-button @click="openDrawer({staffTurnId: record.staffTurnId, record})" type="primary">Xem chi tiết {{ record.id }}</a-button>
                    </div>
                </template>
                <template slot="score" slot-scope="text, record">
                    <div style="text-align: center" :key="record.staffTurnId">
                        <template>{{text}}%</template>
                    </div>
                </template>
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
            </a-table>
        </div>

        <a-drawer
            :width="800"
            closable
            :visible="visible"
            @close="onClose"
            :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
        >
            <div class="table_header">
                <h1>Chi tiết lượt test</h1>
                <div v-if="recordSelected" class="header_info">
                    <span>Nhân sự: <strong>{{ recordSelected.email }}</strong></span>
                    <span>Ngày thực hiện: <strong>{{ moment(recordSelected.workDate).format("DD/MM/YYYY") }}</strong></span>
                </div>
            </div>
            <a-table :columns="detailColumns" :dataSource="details" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="false">
                <template slot="correct" slot-scope="text, record">
                    <div style="text-align: center" :key="record.question">
                        <a-checkbox :checked="record.correct" />
                    </div>
                </template>
                <template slot="answers" slot-scope="text, record">
                    <div :key="record.question">
                        <ul style="padding-left: 20px">
                            <li v-for="(answer, index) in parseAnwers(record.answers)" :key="index" >{{ answer }}</li>
                        </ul>
                    </div>
                </template>
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
            </a-table>
        </a-drawer>

    </a-layout-content>
</template>

<script>
    import moment from 'moment'
    import locale from '../../configs/datePickerLocale'
    import { mapGetters, mapActions } from 'vuex';
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const reportRepository = RepositoryFactory.get('report');
    moment.locale("vi-vn")

    export default {
        name: "TestHistoryReport",
        data() {
            return {
                visible: false,
                searchText: '',
                searchInput: null,
                locale,
                currentDate: moment(),
                salonId: 0,
                data: [],
                loading: false,
                pagination: {
                    pageSize: 15,
                    showTotal: (total, range) => `Hiển thị từ ${range[0]} - ${range[1]} / ${total} dòng`
                },
                columns: [
                    {
                        title: 'Id',
                        dataIndex: 'staffId',
                        key: 'staffId',
                        width: '5%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.staffId.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    }, {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        width: '5%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Họ và tên',
                        dataIndex: 'fullName',
                        key: 'fullName',
                        width: '20%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Vị trí',
                        dataIndex: 'staffType',
                        key: 'staffType',
                        width: '10%',
                        sorter: (a, b) => { return a.staffType.toUpperCase().localeCompare(b.staffType.toUpperCase())}
                    },
                    {
                        title: 'Level',
                        dataIndex: 'skillLevel',
                        key: 'skillLevel',
                        width: '10%',
                        sorter: (a, b) => { return a.skillLevel.toUpperCase().localeCompare(b.skillLevel.toUpperCase())}
                    },
                    {
                        title: 'Salon',
                        dataIndex: 'salon',
                        key: 'salon',
                        width: '10%'
                    },
                    {
                        title: 'Hoàn thành',
                        dataIndex: 'complete',
                        key: 'complete',
                        width: '7%',
                        scopedSlots: { customRender: 'complete' },
                        filters: [
                            { text: 'Đã hoàn thành', value: 'true' },
                            { text: 'Chưa hoàn thành', value: 'false' }
                        ],
                        onFilter: (value, record) => record.complete.toString() === value
                    },
                    {
                        title: 'Đạt',
                        dataIndex: 'pass',
                        key: 'pass',
                        width: '7%',
                        scopedSlots: { customRender: 'pass' },
                        filters: [{ text: 'Đạt', value: 'true' }, { text: 'Không đạt', value: 'false' }],
                        onFilter: (value, record) => record.pass.toString() === value
                    },
                    {
                        title: 'Kết quả',
                        dataIndex: 'score',
                        key: 'score',
                        width: '7%',
                        scopedSlots: { customRender: 'score' },
                    },
                    {
                        title: 'Luợt test',
                        dataIndex: 'turn',
                        key: 'turn',
                        width: '7%'
                    },
                    {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        width: '20%',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                recordSelected: null,
                details: [],
                detailColumns: [
                    {
                        title: 'STT',
                        dataIndex: 'incr',
                        key: 'incr',
                        width: '5%'
                    },
                    {
                        title: 'Câu hỏi',
                        dataIndex: 'question',
                        key: 'question',
                        width: '30%'
                    },
                    {
                        title: 'Câu trả lời',
                        dataIndex: 'answers',
                        key: 'answers',
                        scopedSlots: { customRender: 'answers' },
                        width: '40%'
                    },
                    {
                        title: 'Kết quả',
                        dataIndex: 'correct',
                        key: 'correct',
                        scopedSlots: { customRender: 'correct' },
                        width: '10%'
                    },
                    {
                        title: 'Nhóm câu hỏi',
                        dataIndex: 'groupName',
                        key: 'groupName',
                        width: '10%'
                    }
                ]
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons'
            ])
        },
        methods: {
            ...mapActions('authenticate',[
                'refreshToken'
            ]),
            moment,
            onChange(date) {
                this.currentDate = date
            },
            async handleGetReport() {
                if (this.salonId.length === 0) {
                    await this.$message.error('Danh sách salon không được bỏ trống!!');
                    return;
                }
                try {
                    this.loading = true;
                    let response = await reportRepository.testHistoryReport({ salonId: this.salonId, workDateInt: this.currentDate.format("YYYYMMDD") });

                    if (response.data.meta.success) {
                        this.data = response.data.data;
                    }
                } catch (e) {
                    if (e.message.includes("401")) {
                        await this.refreshToken();
                        this.handleGetReport();
                    } else {
                        this.openNotification(e.message)
                    }
                } finally {
                    this.loading = false;
                }
            },
            filterOption(inputValue, options) {
                return options.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            },
            onSelectChange(value) {
                // eslint-disable-next-line no-console
                this.salonId = value;
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            async openDrawer({staffTurnId, record}) {
                try {
                    this.loading = true;
                    this.recordSelected = record;
                    let response = await reportRepository.testHistoryDetails({ staffTurnId });

                    if (response.data.meta.success) {
                        this.details = response.data.data.map( (item,index) => {
                            return {...item, incr: index + 1}
                        });
                        this.visible = true;
                    }
                } catch (e) {
                    this.openNotification(e.message)
                } finally {
                    this.loading = false;
                }
            },
            onClose() {
                this.visible = false;
            },
            parseAnwers(answers) {
                return answers.split("\n")
            }
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header__action {
        display: flex;
        flex-direction: row;
    }
    .filter-item span {
        font-weight: bold;
    }
    .report {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .table_header, .header_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header_info span {
        margin: 10px    ;
    }

</style>